import React from 'react';
import { Link } from 'gatsby';
import { QueryClient, QueryClientProvider } from 'react-query';
import SYSTEM_STRINGS from '../utility/data/system/systemStrings.yaml';

/*---CVT---*/
import { CvtColorSchema } from '@conventic-web/internal.global';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';

/*---Components---*/
import Layout from '../components/skeleton_page/layout';
import Seo from '../components/skeleton_page/seo';
import CardSocialmedia from '../components/Socialmedia_comps/cardSocialmediaHome';
import ButtonFullPage from '../components/button/buttonFullPage';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../global/sharedObjects';

/*---Images---*/
import ImageWallBackground from '../images/Pics/pic_home_social_JPG.jpg';
import ImageKfStageUntereiten from '../images/Pics/pic_kf_stage_untereiten.svg';

const NotFoundPage = () => {
  const queryClient = new QueryClient();

  const styles = {
    link: {
      textDecoration: 'none',
    },
  };
  return (
    <Layout>
      <Seo title={SYSTEM_STRINGS.COMPONENTS.PAGE_404.TITLE} />

      <BlockTop
        headline={SYSTEM_STRINGS.COMPONENTS.PAGE_404.TOP_HEADLINE}
        subheadline={SYSTEM_STRINGS.COMPONENTS.PAGE_404.TOP_SUBHEADLINE}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={ImageKfStageUntereiten}
        zIndex={6}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.white}
      />

      <BlockStandard
        headline={SYSTEM_STRINGS.COMPONENTS.PAGE_404.STARTPAGE_HEADLINE}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        color={CvtColorSchema.fontDarkGray}
        bgColor={CvtColorSchema.bgGray}
        zIndex={5}
        padding={PaddingObj.PaddingIdle}
      >
        <Link style={styles.link} to={SYSTEM_STRINGS.LINKS.INTERN.HOME}>
          <ButtonFullPage>{SYSTEM_STRINGS.LINKS.NAME.HOME}</ButtonFullPage>
        </Link>
      </BlockStandard>

      <BlockStandard
        bgImage={ImageWallBackground}
        color={CvtColorSchema.fontDarkGray}
        headline={SYSTEM_STRINGS.COMPONENTS.PAGE_404.SOCIALWEB_HEADLINE}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={4}
        padding={PaddingObj.PaddingIdle}
      >
        <QueryClientProvider client={queryClient}>
          <CardSocialmedia />
        </QueryClientProvider>
      </BlockStandard>
    </Layout>
  );
};
export default NotFoundPage;
